import API from '../modules/API';
import {isEmpty, twoChars, isValidDate, dateTimeDiff} from '../modules/tools';
import Config from '../modules/config';

let timeoutId = null;
let pageVisibile = true;

document.addEventListener('visibilitychange', () => {
	pageVisibile = (document.visibilityState === 'visible');
});

const collator = new Intl.Collator('fr', {numeric: true, sensitivity: 'base'});

export default () => {
	return {
		template: require('/src/pages/competition.html'),
		data() {
			return {
				// competition
				competition: null,
				competitionError: null,
				competitionLoading: false,
				// stage
				currentStage: null,
				stageError: null,
				stageLoading: false,
				// live positions
				liveTimePosition: {},
				searchTracker: '',
				competitionTrackersSearched: [],
				trackersPan2Map: [],
			};
		},
		watch: {
			'$route.params.id'() {
				this.getCompetition();
			},
			'$route.params.stageId'() {
				this.selectStage();
			},
			'$live.count'() {
				this.setTrackerLiveTimePosition();
			},
			'searchTracker'() {
				this.onSearchTracker(this.searchTracker);
			},
		},
		async created() {
			await this.getCompetition();

			this.trackersPan2Map = [];

			if (this.competition) {
				const localTrackersPan2Map = window.localStorage.getItem(this.competition.id);
				try {
					if (localTrackersPan2Map !== null) {
						this.trackersPan2Map = JSON.parse(localTrackersPan2Map);
					} else {
						this.trackersPan2Map = [];
					}
				} catch (exception) {
					this.trackersPan2Map = [];
				}
			}
		},
		unmounted() {
			this.stopGetTrackers();
		},
		computed: {
			stageEvents() {
				let globalEvents = [
					{
						code: 'START',
						num: 0,
						event: "0",
						name: 'Départ',
						km: 0,
						type: 'S',
					},
				];
				if (this.currentStage !== null) {
					if (isEmpty(this.currentStage.events) === false) {
						globalEvents = [...globalEvents, ...this.currentStage.events];
					}
					if (isEmpty(this.currentStage.intermediates) === false) {
						globalEvents = [...globalEvents, ...this.currentStage.intermediates];
					}
					globalEvents.push({
						code: 'END',
						num: 0,
						event: "0",
						name: 'Arrivée',
						km: this.currentStage.length,
						type: 'A',
					});
				}
				globalEvents = globalEvents.filter((e) => e.hidden !== true);

				return globalEvents.sort((a, b) => {
					if (a.km > b.km) {
						return 1;
					}
					if (a.km < b.km) {
						return -1;
					}
					if (a.km === b.km) {
						return 0;
					}
				});
			},
		},
		methods: {
			async getCompetition() {
				const competitionId = this.$route.params.id;

				this.competitionError = null;
				this.competitionLoading = false;

				try {
					this.competitionLoading = true;
					this.competition = await API.getCompetition(competitionId, {$select: ['id', 'name', 'stages', 'trackers', 'tracking']});
					if (isEmpty(this.competition) === false) {
						document.title = this.competition.name;
						this.competitionTrackersSearched = this.competition.trackers;
						// init displayed property
						this.onSearchTracker(this.searchTracker);

						this.startGetTrackers();
						this.selectStage();
					}
				} catch (exception) {
					this.competitionError = exception;
				} finally {
					this.competitionLoading = false;
				}
			},
			selectStage() {
				if (isEmpty(this.$route.params.stageId) === true) {
					const newDate = new Date();
					const stageDateFormmatted = `${newDate.getFullYear()}-${twoChars(newDate.getMonth() + 1)}-${twoChars(newDate.getDate())}`;

					for (let stage of this.competition.stages) {
						//date: "2023-05-16"
						if (stage.date === stageDateFormmatted) {
							return this.$router.replace(`/competitions/${this.competition.id}/${stage.id}`);
						}
					}
					this.$router.replace(`/competitions/${this.competition.id}/${this.competition.stages[0].id}`);
				} else {
					this.getStage();
				}
			},
			startGetTrackers() {
				//console.log('startGetTrackers!');
				this.getTrackers();

				timeoutId = window.setInterval(() => {
					this.getTrackers();
				}, Config.refreshTracker);
			},
			stopGetTrackers() {
				//console.log('stopGetTrackers!');
				window.clearInterval(timeoutId);
			},
			async getTrackers() {
				//pageVisibile = false;

				// no live when page is not visible
				if (pageVisibile === false) {
					return;
				}

				const tempTrackers = [];

				if (isEmpty(this.competition.trackers) === true) {
					return;
				}

				for (let trackerObj of this.competition.trackers) {
					try {
						const trackerLocations = await API.getTracker(trackerObj.id, this.competition.id);
						if (isEmpty(trackerLocations) === false) {
							if (this.trackersPan2Map.includes(trackerObj.id) === true) {
								trackerObj.pan2Map = true;
							} else {
								trackerObj.pan2Map = false;
							}

							const t = Object.assign(trackerLocations[0], trackerObj);
							tempTrackers.push(t);
						}
					} catch (exception) {
						console.error('Could not get tracker:', exception);
					}
				}
				this.$live.trackerPositions = tempTrackers;
				this.$live.count++;
			},
			async getStage() {
				const stageId = this.$route.params.stageId;
				this.stageError = null;
				this.stageLoading = false;

				try {
					this.stageLoading = true;
					this.currentStage = await API.getStage(stageId);
					if (isEmpty(this.currentStage) === false) {
						document.title = this.currentStage.name;
					}
				} catch (exception) {
					this.stageError = exception;
				} finally {
					this.stageLoading = false;
				}
			},
			setTrackerLiveTimePosition() {
				this.liveTimePosition = {};

				for (let trackerPosition of this.$live.trackerPositions) {
					const newDate = new Date(trackerPosition.time);
					if (isValidDate(newDate) === true) {
						//trackerPosition.formattedTime = newDate.toLocaleString('fr', {timeZone: 'Europe/Paris', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});
						trackerPosition.formattedTime = dateTimeDiff(newDate);
						this.liveTimePosition[trackerPosition.id] = trackerPosition;
					}
				}
			},
//			centerOnMarker(id) {
//				this.$live.centeredMarkerId = id;
//			},
			fixDist(dist) {
				if (dist > 500) {
					return dist / 1000;
				}
				return dist;
			},
			onSearchTracker(search) {
				const trackers = this.competition.trackers.map((t) => {
					t.displayed = t.name.toLowerCase().includes(search.toLowerCase()) === true;
					return t;
				});

				// sort natural
				this.competitionTrackersSearched = trackers.sort((a, b) => {
					return collator.compare(a.name, b.name);
				});
			},
			onTrackTracker(ev, trackerId) {
				//console.log('onTrackTracker:', ev.target.checked, ev.target.value, trackerId);

				if (ev.target.checked === true) {
					this.trackersPan2Map.push(trackerId);
				} else {
					const idx = this.trackersPan2Map.indexOf(trackerId);
					this.trackersPan2Map.splice(idx, 1);
				}

				window.localStorage.setItem(this.competition.id, JSON.stringify(this.trackersPan2Map));
			},
		},
	};
};
